import { printLog } from "../../../../shared/utils";
import { Resource } from "../interface";
import { useAuthContext } from "../../../../shared/auth/AuthContext";
import useHttp from "../../../../shared/hooks/useHttp";

type ResponseListResources = {
  id: string;
  title: string;
  description: string;
}[];

type ResponseResource = {
  id: string;
  title: string;
  description: string;
  resources: {
    id: string;
    title: string;
    url: string;
    type: string;
    imageFile: {
      id: string;
      nombre: string;
      url: string;
    };
    adjuntoFile?: {
      id: string;
      nombre: string;
      url: string;
    };
  }[];
};

export function useGetOtherResources(): () => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async () => {
    try {
      const response = await http.get("/admin/campaign/other-resources", {
        auth: authContext?.user?.token ?? undefined,
      });
      if (response) {
        return (response as ResponseListResources).map((item) => ({
          titulo: item.title,
          id: item.id,
          descripcion: item.description,
        }));
      } else {
        return [];
      }
    } catch (error) {
      printLog("Error al obtener los recursos >> ", error);
      return [];
    }
  };
}

export function useGetResourceById(): (resourceId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (resourceId: string) => {
    try {
      const response = await http.get(`/admin/campaign/${resourceId}`, {
        auth: authContext?.user?.token ?? undefined,
      });
      const data: ResponseResource = response as any;
      return {
        id: data.id,
        titulo: data.title,
        descripcion: data.description,
        recursos: data.resources,
      };
    } catch (error) {
      printLog("Error al obtener el recurso >> ", error);
      return null;
    }
  };
}

export function useUpdateResource(): (
  payload: Resource,
  resourceId: string
) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (payload: Resource, resourceId: string) => {
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        ...payload,
        tipo: "OTROS",
        recursos: payload.recursos?.map((recurso) => ({
          id: recurso.id,
          tipo: recurso.tipo,
          url: recurso.url,
          titulo: recurso.titulo,
          image: {
            name: recurso.adjunto?.name,
            type: recurso.adjunto?.type,
            size: recurso.adjunto?.size,
          },
        })),
      })
    );

    payload.recursos?.forEach((recurso) => {
      if (recurso.adjunto) {
        formData.append("images", recurso.adjunto);
      }
    });

    printLog("[create:resource] form data >> ", formData);
    try {
      return await http.put(`/admin/campaign/${resourceId}`, {
        payload: formData,
        auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
    } catch (error: unknown) {
      printLog("Error al crear el recurso >> ", error);
      return null;
    }
  };
}

export function useDeleteResource(): (resourceId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (resourceId: string) => {
    try {
      return await http.delete(`/admin/resource/${resourceId}`, {
        auth: authContext?.user?.token ?? undefined,
      });
    } catch (error) {
      printLog("Error al eliminar el recurso >> ", error);
    }
  };
}
