import { Box, Grid, Typography } from "@mui/material";
import { QuestionTypeSelector } from "./QuestionTypeSelector";
import { EditorAdjunto } from "../preguntaComponents/EditorAdjunto";
import { EditorValoresComunes } from "../preguntaComponents/EditorValoresComunes";
import { usePreguntasHandler } from "../preguntaComponents/usePreguntasHandler";
import { PreguntaEntregableModel } from "../../../types";
import { DeliverableQuestionEditor } from "./DeliverableQuestionEditor";
import { CircleBox, CoursePrimaryButtonText } from "../../../shared/Elements";
import { AddRounded } from "@mui/icons-material";

type DeliverableContentEditorProps = {
  preguntas?: PreguntaEntregableModel[];
  onPreguntasChange: (preguntas: PreguntaEntregableModel[]) => void;
  onAdjuntoChange: (tempId: string, file: File) => void;
  onAdjuntoDelete: (tempId: string) => void;
};

export function DeliverableContentEditor(props: DeliverableContentEditorProps) {
  const {
    preguntas,
    handleChangeTipoPregunta,
    handleChangePropPregunta,
    handleEliminarPregunta,
    handleSetAdjunto,
    handleSetPregunta,
    addPregunta,
  } = usePreguntasHandler("ENTREGABLE", {
    preguntas: props.preguntas,
    onPreguntasChange: props.onPreguntasChange,
    onAdjuntosChange: props.onAdjuntoChange,
    onAdjuntosDelete: props.onAdjuntoDelete,
  });

  return (
    <Box>
      {preguntas.map((pregunta, indicePregunta) => {
        const { tipo } = pregunta;
        const updateTipo = handleChangeTipoPregunta(indicePregunta);
        const updateValor = handleChangePropPregunta(indicePregunta);
        const deletePregunta = handleEliminarPregunta(indicePregunta);
        const setAdjunto = handleSetAdjunto(indicePregunta);
        const deleteAdjunto = () => setAdjunto(undefined);
        const setPregunta = handleSetPregunta(indicePregunta);
        return (
          <Grid container alignItems="center" key={indicePregunta} mb={4}>
            <Grid item xs={12} mb={2}>
              <Typography sx={{ fontSize: "1.2rem" }}>
                Selecciona el tipo de pregunta
              </Typography>
            </Grid>
            <QuestionTypeSelector
              tipoSeleccionado={tipo}
              onchangeTipoPregunta={updateTipo}
            />
            {pregunta.tipo !== "" && (
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                rowSpacing={2}
                mt={1}
              >
                <Grid item xs={12}>
                  <Box>
                    <EditorValoresComunes
                      indicePregunta={indicePregunta}
                      pregunta={pregunta}
                      onDelete={deletePregunta}
                      onUpdate={updateValor}
                      textEditor={true}
                    />
                    <EditorAdjunto
                      adjunto={pregunta.adjunto}
                      onAdjuntoChange={setAdjunto}
                      onDelete={deleteAdjunto}
                    />

                    {pregunta.categoria === "ENTREGABLE" && (
                      <DeliverableQuestionEditor
                        pregunta={pregunta}
                        onPreguntaChange={setPregunta}
                      ></DeliverableQuestionEditor>
                    )}
                  </Box>
                </Grid>
                {indicePregunta === preguntas.length - 1 && (
                  <Grid item>
                    <CoursePrimaryButtonText onClick={addPregunta}>
                      <CircleBox sx={{ mr: 0.8 }}>
                        <AddRounded sx={{ fontSize: "14pt" }} />
                      </CircleBox>
                      Añadir pregunta
                    </CoursePrimaryButtonText>
                    {/* <Button size="small" onClick={addPregunta}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <AddCircleOutlineRounded />
                        <Typography
                          sx={{ paddingTop: 1 }}
                        >
                          Añadir pregunta
                        </Typography>
                      </Box>
                    </Button> */}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        );
      })}
    </Box>
  );
}
