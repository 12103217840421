import {Box, IconButton, LinearProgress, Typography} from '@mui/material'
import {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ReactComponent as KeyboardBackspace} from '../../assets/icons/keyboard-backspace.svg';
// import { AntTab, AntTabs } from '../../common/components/CTab';
import {AntTab, AntTabs} from '../../shared/components/CTab';
// import { colorsBase, DesignSystem } from '../../common/theme';
import {colorsBase, DesignSystem} from '../../themes/theme';
import {useGetReporteHome} from './ReporteClient';

import {ReporteInscritos} from './components/ReporteInscritos';
// import { useAuthContext } from '../../common/auth/AuthContext';
import {useAuthContext} from '../../shared/auth/AuthContext';
import {printLog} from '../../shared/utils';
import {ReporteCursos} from "./components/ReporteCursos";
import {ReporteMachometro} from "./components/ReporteMachometro"


export const ReportePage = () => {
  const navigate = useHistory();
  const {user} = useAuthContext();
  const {state}: any = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [participantes, setParticipantes] = useState([]);
  const getReporteHome2 = useGetReporteHome()

  const {userRol} = state || user?.rol || 'MONITOR';
  // const { regionIdDefault } = state;

  // printLog('hay region id default :: ', regionIdDefault);

  /// From Client
  async function fetchReporteHome() {
    //const fetchedReporteHome = await getReporteHome();
    const fetchedReporteHome = getReporteHome2();

    printLog('reporte home :: ', fetchedReporteHome);
    // setParticipantes(fetchedReporteHome.participantes as unknown as [])

  }

  // useEffect(() => {
  //   //fetchReporteHome();
  //   setLoading(false);
  //   return () => {
  //     setParticipantes([]);
  //     setLoading(false);
  //   }
  // }, []);

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate.push(`?tab=${newValue}`);
  }, [navigate]);

  useEffect(() => {
    const updateTabFromURL = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const tab = searchParams.get('tab');
      if (tab && parseInt(tab) !== value) {
        setValue(parseInt(tab));
      }
    };

    updateTabFromURL();

    // Escuchar cambios en el historial del navegador
    window.addEventListener('popstate', updateTabFromURL);

    // Cleanup para evitar memory leaks
    return () => {
      window.removeEventListener('popstate', updateTabFromURL);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <Box display="flex" justifyContent="space-between" alignItems="center" alignContent="center" mb={1}>
      <Box display="flex" alignItems="center">
        <IconButton size="small" onClick={() => navigate.push("/")}>
          <KeyboardBackspace/>
        </IconButton>
        <Typography sx={{...DesignSystem?.titulo1, pl: 2}}>
          Reportes
        </Typography>
      </Box>
    </Box>
    {loading ? (<LinearProgress/>) : (<Box sx={{
        background: "rgba(255, 255, 255, 0.5)",
        boxShadow: "1.5px 2.6px 10px rgba(119, 119, 119, 0.1)",
        boxSizing: "border-box",
        borderRadius: "8px",
        p: 2
      }}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab
            label="Inscritos"
            sx={{
              color: colorsBase.Verde2,
              backgroundColor: colorsBase.Blanco,
              border: `1px solid ${colorsBase.Verde2}`,
              '&.Mui-selected': {
                backgroundColor: colorsBase.Verde2
              }
            }}
          />
          <AntTab
            label={"Cursos"}
            sx={{
              color: colorsBase.Verde2,
              backgroundColor: colorsBase.Blanco,
              border: `1px solid ${colorsBase.Verde2}`,
              '&.Mui-selected': {
                backgroundColor: colorsBase.Verde2
              }
            }}
          />
          <AntTab
            label={"Machometro"}
            sx={{
              color: colorsBase.Verde2,
              backgroundColor: colorsBase.Blanco,
              border: `1px solid ${colorsBase.Verde2}`,
              '&.Mui-selected': {
                backgroundColor: colorsBase.Verde2
              }
            }}
          />
        </AntTabs>
        {/* INI - Tipos de reportes */}
        {value === 0 && (<ReporteInscritos/>)}
        {value === 1 && (<ReporteCursos/>)}
        {value === 2 && (<ReporteMachometro/>)}

        {/* { userRol === 'MONITOR' && value === 1 && (
              <ReporteMonitor
                participantes={participantes}
                rol={userRol}
              />
            ) } */}
      </Box>)}
  </>
}
