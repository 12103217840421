import React, { useEffect } from "react";
import {
  NeutralButton,
  PrimaryButton,
  RecursoDialog,
} from "../shared/Elements";
import { Box, Grid, IconButton, Radio, Typography } from "@mui/material";

import {
  AudiotrackRounded,
  DescriptionRounded,
  PlayCircleRounded,
} from "@mui/icons-material";
import theme from "../../../../themes";
import CInputForm from "../../../../shared/components/form/CInputForm";
import { CFilePicker } from "../../../../shared/components/form/CFilePicker";
import { isValidUrl, printLog } from "../../../../shared/utils";
import { useCampaignContext } from "../context";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { colorsBase } from "../../../../themes/theme";
import { ReactComponent as CancelCircleIcon } from "../../../../assets/icons/cancelCircle.svg";

type DialogCampaignResourceFormProps = {
  open: boolean;
  onClose: () => void;
  idxResource: string | null;
};

export type ContentResource = {
  tipo: string;
  titulo: string;
  url: string;
  adjunto: any;
};

export const DialogCampaignResourceForm = ({
  open,
  onClose,
  idxResource,
}: DialogCampaignResourceFormProps) => {
  const [campaign, dispatch] = useCampaignContext();

  const [currentUrlImage, setCurrentUrlImage] = React.useState<string | null>(
    null
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm<ContentResource>({
    defaultValues: {
      tipo: "",
      url: "",
      titulo: "",
      adjunto: null,
    },
  });

  const handleOnSaveRecurso: SubmitHandler<ContentResource> = (data) => {
    printLog("Procesando recurso.......");
    printLog("ContentResource > ", data);
    if (idxResource) {
      printLog("Editando recurso.......");
      dispatch({
        type: "EDIT_RESOURCE",
        idxResource,
        payload: {
          ...data,
        },
      });
    } else {
      printLog("Guardando recurso.......");
      dispatch({
        type: "ADD_RESOURCE",
        payload: {
          ...data,
        },
      });
    }
    onClose();
  };

  useEffect(() => {
    if (idxResource) {
      printLog("setear recurso...", idxResource);
      const _recurso = campaign.recursos[Number(idxResource)];
      setValue("tipo", _recurso.tipo);
      setValue("titulo", _recurso.titulo);
      setValue("url", _recurso.url);
      setValue("adjunto", _recurso.adjunto);
      if (_recurso.imageFile?.url) {
        setCurrentUrlImage(_recurso.imageFile?.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <RecursoDialog
        open={open}
        scroll="paper"
        onClose={onClose}
        ctitle="Selecciona el tipo de recurso"
      >
        <Box component="form" onSubmit={handleSubmit(handleOnSaveRecurso)}>
          <Box mt={3} mb={2} px={3}>
            <Box display={"flex"} gap={2} justifyContent={"space-between"}>
              <Box
                component={Radio}
                sx={{
                  height: "6rem",
                  flex: 1,
                  background:
                    watch("tipo") === "ARCHIVO"
                      ? "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)"
                      : theme.palette.grey[200],
                  borderRadius: "10px",
                  color: watch("tipo") === "ARCHIVO" ? "white" : "black",
                  "&:hover": {
                    background:
                      watch("tipo") !== "ARCHIVO"
                        ? theme.palette.grey[300]
                        : "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                  },
                }}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                alignItems={"center"}
                justifyContent={"center"}
                px={2}
                pt={2}
                pb={1}
                value="ARCHIVO"
                {...register("tipo", {
                  required: "El campo Tipo es requerido",
                })}
                icon={
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <DescriptionRounded sx={{ fontSize: "3rem" }} />
                    ARCHIVO
                  </Box>
                }
                checkedIcon={
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    sx={{
                      color: watch("tipo") === "ARCHIVO" ? "white" : "black",
                    }}
                  >
                    <DescriptionRounded sx={{ fontSize: "3rem" }} />
                    ARCHIVO
                  </Box>
                }
              />
              <Box
                component={Radio}
                sx={{
                  height: "6rem",
                  flex: 1,
                  background:
                    watch("tipo") === "VIDEO"
                      ? "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)"
                      : theme.palette.grey[200],
                  borderRadius: "10px",
                  color: watch("tipo") === "VIDEO" ? "white" : "black",
                  "&:hover": {
                    background:
                      watch("tipo") !== "VIDEO"
                        ? theme.palette.grey[300]
                        : "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                  },
                }}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                alignItems={"center"}
                justifyContent={"center"}
                px={2}
                pt={2}
                pb={1}
                value="VIDEO"
                {...register("tipo", {
                  required: "El campo Tipo es requerido",
                })}
                icon={
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <PlayCircleRounded sx={{ fontSize: "3rem" }} />
                    VIDEO
                  </Box>
                }
                checkedIcon={
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    sx={{
                      color: watch("tipo") === "VIDEO" ? "white" : "black",
                    }}
                  >
                    <PlayCircleRounded sx={{ fontSize: "3rem" }} />
                    VIDEO
                  </Box>
                }
              />
              <Box
                component={Radio}
                sx={{
                  height: "6rem",
                  flex: 1,
                  background:
                    watch("tipo") === "AUDIO"
                      ? "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)"
                      : theme.palette.grey[200],
                  borderRadius: "10px",
                  color: watch("tipo") === "AUDIO" ? "white" : "black",
                  "&:hover": {
                    background:
                      watch("tipo") !== "AUDIO"
                        ? theme.palette.grey[300]
                        : "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                  },
                }}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                alignItems={"center"}
                justifyContent={"center"}
                px={2}
                pt={2}
                pb={1}
                value="AUDIO"
                {...register("tipo", {
                  required: "El campo Tipo es requerido",
                })}
                icon={
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <AudiotrackRounded sx={{ fontSize: "3rem" }} />
                    AUDIO
                  </Box>
                }
                checkedIcon={
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    sx={{
                      color: watch("tipo") === "AUDIO" ? "white" : "black",
                    }}
                  >
                    <AudiotrackRounded sx={{ fontSize: "3rem" }} />
                    AUDIO
                  </Box>
                }
              />
            </Box>
            {errors.tipo && (
              <Typography
                component={"span"}
                sx={{
                  textAlign: "left",
                  fontSize: ".8rem",
                  color: theme.palette.error.main,
                }}
              >
                {errors.tipo &&
                errors.tipo.type &&
                errors.tipo.type === "required"
                  ? "* "
                  : ""}
                {errors.tipo.message ? errors.tipo.message : ""}
              </Typography>
            )}
          </Box>

          <Box px={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box mb={1.5}>
                  <Typography fontWeight={"bold"}>Titulo</Typography>
                </Box>
                <Controller
                  name="titulo"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El campo Titulo es requerido",
                    validate: async (value) => {
                      if (value.trim() === "")
                        return "El campo Titulo es inválido";
                    },
                  }}
                  render={({ field }) => (
                    <CInputForm
                      type="text"
                      placeholder="Escribe el título del recurso"
                      fullWidth
                      {...field}
                      ref={null}
                      error={errors.titulo}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mb={1.5}>
                  <Typography fontWeight={"bold"}>Url</Typography>
                </Box>
                <Controller
                  name="url"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El campo Url es requerido",
                    validate: async (value) => {
                      if (!isValidUrl(value)) return "No es una url válida";
                      if (value.trim() === "")
                        return "El campo Url es inválido";
                    },
                  }}
                  render={({ field }) => (
                    <CInputForm
                      type="text"
                      placeholder="Escribe la URL del recurso que deseas enlazar"
                      fullWidth
                      {...field}
                      ref={null}
                      error={errors.url}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mb={1.5}>
                  <Typography fontWeight={"bold"}>Adjunto miniatura</Typography>
                </Box>
                {!watch("adjunto") && !currentUrlImage && (
                  <Controller
                    name="adjunto"
                    control={control}
                    rules={{
                      required: "El campo Adjunto miniatura es requerido",
                    }}
                    render={({ field }) => (
                      <CFilePicker
                        placeholder="Carga la imagen miniatura del recurso"
                        name="imagenRecurso"
                        accept="image/*"
                        handleChange={(e) => {
                          field.onChange(e.target.files[0]);
                        }}
                      />
                    )}
                  />
                )}

                {(currentUrlImage || watch("adjunto")) && (
                  <Box
                    sx={{
                      position: "relative",
                      width: 120,
                      height: 120,
                      borderRadius: "8px",
                      overflow: "hidden",
                      boxShadow: 2,
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                      src={
                        currentUrlImage ||
                        URL.createObjectURL(getValues("adjunto"))
                      }
                      alt="miniatura"
                    />
                    <IconButton
                      onClick={() => {
                        setValue("adjunto", null);
                        setCurrentUrlImage(null);
                      }}
                      size="small"
                      title="Eliminar miniatura"
                      sx={{
                        position: "absolute",
                        top: 4,
                        right: 4,
                        stroke: colorsBase.Rojo,
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 1)",
                        },
                      }}
                    >
                      <CancelCircleIcon width="20" height="20" />
                    </IconButton>
                  </Box>
                )}
                {errors.adjunto && (
                  <Typography
                    component={"span"}
                    sx={{
                      textAlign: "left",
                      fontSize: ".8rem",
                      color: theme.palette.error.main,
                    }}
                  >
                    {errors.adjunto &&
                    errors.adjunto.type &&
                    errors.adjunto.type === "required"
                      ? `* ${errors.adjunto.message ?? ""}`
                      : ""}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              paddingTop: 5,
              paddingBottom: 3,
              position: "relative",
              zIndex: 99,
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              <NeutralButton onClick={onClose}>Cancelar</NeutralButton>
              <PrimaryButton sx={{ ml: 2 }} type="submit">
                {idxResource ? "Guardar" : "Crear"}
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </RecursoDialog>
    </React.Fragment>
  );
};
