import { printLog } from "../../../../shared/utils";
import { IBannerForm } from "../BannerInterface";
import useHttp from "../../../../shared/hooks/useHttp";
import { useAuthContext } from "../../../../shared/auth/AuthContext";
import { Alerts } from "../../../../shared/hooks/useAlert";

export function useGetBanners(): () => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async () => {
    try {
      const response = await http.get("/admin/comunicacion/banners", {
        auth: authContext?.user?.token ?? undefined,
      });
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (error) {
      printLog("Error al obtener banners >>> ", error);
    }
  };
}

export function useGetBannerById(): (bannerId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (bannerId: string) => {
    try {
      const response = await http.get(
        `/admin/comunicacion/banner/${bannerId}`,
        {
          auth: authContext?.user?.token ?? undefined,
        }
      );
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (error) {
      printLog("Error al obtener banners >>> ", error);
    }
  };
}

export function useCreateBanner(): (payload: IBannerForm) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (payload: IBannerForm) => {
    const arrayData = new FormData();
    arrayData.append("titulo", payload.titulo);
    arrayData.append("esDestacado", JSON.stringify(payload.esDestacado));
    if (
      payload.urlRecurso &&
      payload.urlRecurso.length > 0 &&
      (!payload.adjunto || payload.adjunto.length === 0)
    ) {
      arrayData.append("urlRecurso", payload.urlRecurso);
    }
    if (payload.adjunto.length > 0) {
      arrayData.append("adjunto", payload.adjunto[0]);
    }
    if (payload.miniatura.length > 0) {
      arrayData.append("miniatura", payload.miniatura[0]);
    }
    printLog("[create:banner] >>> ", arrayData);
    try {
      const response = await http.post(`/admin/comunicacion/banner`, {
        payload: arrayData,
        auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
      if (response) {
        return true;
      } else {
        return null;
      }
    } catch (error) {
      Alerts.showError({ description: "Error al crear banner" });
      printLog("Error al crear banner >>> ", error);
    }
  };
}

export function useUpdateBanner(): (
  payload: IBannerForm,
  bannerId: string
) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (payload: IBannerForm, bannerId: string) => {
    const arrayData = new FormData();
    arrayData.append("titulo", payload.titulo);
    if (payload.urlRecurso && payload.urlRecurso.length > 0) {
      arrayData.append("urlRecurso", payload.urlRecurso);
    }
    arrayData.append("esDestacado", JSON.stringify(payload.esDestacado));
    if (payload.adjunto.length > 0) {
      arrayData.append("adjunto", payload.adjunto[0]);
    }

    if (payload.miniatura.length > 0) {
      arrayData.append("miniatura", payload.miniatura[0]);
    }
    printLog("[update:banner] >>> ", arrayData);
    try {
      const response = await http.put(
        `/admin/comunicacion/banner/${bannerId}`,
        {
          payload: arrayData,
          auth: authContext?.user?.token ?? undefined,
          withFiles: true,
        }
      );
      if (response) {
        Alerts.showSuccess({ description: "Banner actualizado correctamente" });
        return response;
      } else {
        return null;
      }
    } catch (error) {
      Alerts.showError({ description: "Error al actualizar banner" });
      printLog("Error al actualizar banner >>> ", error);
    }
  };
}

export function useDeleteBanner(): (bannerId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (bannerId: string) => {
    try {
      const response = await http.delete(
        `/admin/comunicacion/banner/${bannerId}`,
        {
          auth: authContext?.user?.token ?? undefined,
          withFiles: true,
        }
      );
      if (response) {
        Alerts.showSuccess({ description: "Banner eliminado correctamente" });
        return response;
      } else {
        return null;
      }
    } catch (error) {
      Alerts.showError({ description: "Error al eliminar banner" });
      printLog("Error al actualizar banner >>> ", error);
    }
  };
}
