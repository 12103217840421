import {
  Alert,
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PlayArrow from "@mui/icons-material/PlayArrowRounded";
import VolumeUp from "@mui/icons-material/VolumeUpRounded";
import { DescriptionRounded as DescriptionIcon } from "@mui/icons-material";
import theme from "../../../themes";
import { useEffect, useState } from "react";
import { CommunicationResourceItem } from "./ResourceItem";
import bgCampania from "./../../../assets/Communication/campaign/texturas.png";
import ReactGA from "react-ga4";
import useHttp from "../../../shared/hooks/useHttp";
import { printLog } from "../../../shared/utils";

const StyledButton = styled(Button)(() => ({
  background: alpha(theme.palette.common.white, 0.4),
  borderRadius: "8px",
  ".avatar-icon": {
    width: 26,
    height: 26,
    background: theme.palette.common.white,
    color: "black",
  },
  "&:hover": {
    background: theme.palette.common.white,
    ".avatar-icon": {
      background: theme.palette.primary.main,
    },
  },
}));

export const TipoRecurso = {
  ARCHIVO: "ARCHIVO",
  VIDEO: "VIDEO",
  AUDIO: "AUDIO",
};

type CampaignMain = {
  title: string;
  description: string;
  urlImage: string;
  resources: {
    id: string;
    titulo: string;
    url: string;
    tipo: string;
    urlImage: string;
  }[];
};

export const MainCampaignCard = () => {
  const theme = useTheme();
  const http = useHttp();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const [verArchivos, setVerArchivos] = useState<boolean>(false);
  const [tipoArchivos, setTipoArchivos] = useState<string>();

  const [campaign, setCampaign] = useState<CampaignMain | null>(null);

  const verTipoArchivo = (tipoArchivo: string) => {
    setVerArchivos(true);
    setTipoArchivos(tipoArchivo);
    ReactGA.event({
      category: "Campanña",
      action: `Campaña visitado - ${tipoArchivo}`,
      label: `Campaña Nacional - ${tipoArchivo}`,
    });
  };

  const cancelar = () => {
    setVerArchivos(false);
    setTipoArchivos("");
  };

  const handleGetCampaign = async () => {
    try {
      const response = await http.get("/comunicacion/campaign/principal", {
        auth: undefined,
        withFiles: true,
      });
      console.log("response", response);
      if (response) {
        setCampaign(response as CampaignMain);
      }
    } catch (error) {
      printLog("Error al obtener la campaña >> ", error);
    }
  };

  useEffect(() => {
    handleGetCampaign();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {campaign && (
        <Box pb={5}>
          {!verArchivos && (
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ background: "white", position: "relative" }}
              >
                <Box m={0} p={0} sx={{ height: "300px" }}>
                  <Typography
                    variant="h4"
                    textAlign={"center"}
                    fontWeight={"bold"}
                    sx={{ px: 3, mt: 3 }}
                  >
                    {campaign?.title}
                  </Typography>
                </Box>
                <img
                  src={bgCampania}
                  alt="bg-campania"
                  style={{
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ background: "#cecece", p: 5 }}>
                <Box
                  display="flex"
                  flexDirection={"row"}
                  gap={5}
                  alignContent={"center"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography fontWeight={"bold"}>Descripción</Typography>
                    <Box my={1} />
                    <Typography textAlign={"justify"}>
                      {campaign.description}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={3}>
                    <StyledButton
                      variant="contained"
                      disableElevation
                      startIcon={
                        <Avatar className="avatar-icon">
                          <DescriptionIcon fontSize="small" />
                        </Avatar>
                      }
                      onClick={() => verTipoArchivo(TipoRecurso.ARCHIVO)}
                    >
                      Archivos
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      startIcon={
                        <Avatar className="avatar-icon">
                          <PlayArrow fontSize="small" />
                        </Avatar>
                      }
                      disableElevation
                      onClick={() => verTipoArchivo(TipoRecurso.VIDEO)}
                    >
                      Vídeos
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      disableElevation
                      startIcon={
                        <Avatar className="avatar-icon">
                          <VolumeUp fontSize="small" />
                        </Avatar>
                      }
                      onClick={() => verTipoArchivo(TipoRecurso.AUDIO)}
                    >
                      Audio
                    </StyledButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          {verArchivos && (
            <Grid
              container
              sx={{
                background: "white",
                overflow: "auto",
                position: "relative",
                height: xs ? "700px" : "300px",
              }}
            >
              <Grid item xs={12} md={4} sx={{ p: 3, position: "relative" }}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={3}
                  sx={{ position: "sticky", top: 10 }}
                >
                  <Typography
                    variant="h4"
                    textAlign={"center"}
                    fontWeight={"bold"}
                  >
                    {campaign.title}
                  </Typography>
                  {/* <Button variant="contained">Vídeos</Button> */}
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    gap={2}
                  >
                    {tipoArchivos === TipoRecurso.ARCHIVO && (
                      <Avatar
                        className="avatar-icon"
                        sx={{ background: theme.palette.primary.main }}
                      >
                        <DescriptionIcon fontSize="small" />
                      </Avatar>
                    )}
                    {tipoArchivos === TipoRecurso.VIDEO && (
                      <Avatar
                        className="avatar-icon"
                        sx={{ background: theme.palette.primary.main }}
                      >
                        <PlayArrow fontSize="small" />
                      </Avatar>
                    )}
                    {tipoArchivos === TipoRecurso.AUDIO && (
                      <Avatar
                        className="avatar-icon"
                        sx={{ background: theme.palette.primary.main }}
                      >
                        <VolumeUp fontSize="small" />
                      </Avatar>
                    )}
                    <Box
                      sx={{
                        background: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        textTransform: "uppercase",
                        px: 3,
                        py: 0.5,
                        borderRadius: "8px",
                      }}
                    >
                      <Typography>{tipoArchivos}s</Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "8px" }}
                    onClick={cancelar}
                  >
                    Limpiar
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} sx={{ p: 3 }}>
                <Box display={"flex"} flexDirection={"column"} gap={4} px={1}>
                  {!campaign.resources.filter(
                    (item) => item.tipo === tipoArchivos
                  ).length && (
                    <Alert severity="warning">
                      No existe recursos de tipo {tipoArchivos}.
                    </Alert>
                  )}
                  {campaign.resources.filter(
                    (item) => item.tipo === tipoArchivos
                  ).length > 0 &&
                    campaign.resources
                      .filter((item) => item.tipo === tipoArchivos)
                      .map((recurso, idxRecurso) => (
                        <Box key={idxRecurso}>
                          <CommunicationResourceItem
                            titulo={recurso.titulo}
                            enlace={recurso.url}
                            imagen={recurso.urlImage}
                            tipo={recurso.tipo}
                            color={theme.palette.primary.main}
                          />
                        </Box>
                      ))}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};
