export type TipoPreguntaQuiz =
  | "SELECCION_UNICA"
  | "SELECCION_MULTIPLE"
  | "PREGUNTA_ABIERTA";

export type TipoPreguntaEntregable = "CARGA_ARCHIVO" | "CARGA_VIDEO";

export enum TipoPregunta {
  SELECCION_UNICA = "SELECCION_UNICA",
  SELECCION_MULTIPLE = "SELECCION_MULTIPLE",
  PREGUNTA_ABIERTA = "PREGUNTA_ABIERTA",
  // Quiz entregables
  CARGA_ARCHIVO = "CARGA_ARCHIVO",
  CARGA_VIDEO = "CARGA_VIDEO",
}

export type PlantillaCursoParams = {
  nombre: string;
  descripcion: string | null;
  unidades: PlantillaUnidadCursoParams[];
  imagenCurso: AdjuntoMetadata | null;
};
/// OK

export type PlantillaUnidadCursoParams = {
  titulo: string;
  objetivo: string | null;
  contenido: InstanciaUnidadCursoContenido[];
};

export type InstanciaUnidadCursoContenido =
  | ContenidoTexto
  | ContenidoVideo
  | ContenidoQuiz
  | ContenidoEntregable
  | ContenidoVacio;

export type ContenidoVacio = {
  tipo: "";
  titulo: string;
};

export type ContenidoTexto = {
  tipo: "TEXTO";
  titulo: string;
  texto: string;
};

export type ContenidoVideo = {
  tipo: "VIDEO";
  titulo: string;
  link: string;
};

export type ContenidoQuiz = {
  tipo: "QUIZ";
  titulo: string;
  isEvaluated: boolean;
  preguntas: Pregunta[];
};

export type ContenidoEntregable = {
  tipo: "ENTREGABLE";
  titulo: string;
  preguntas: PreguntaEntregable[];
};

export type Pregunta =
  | PreguntaSeleccionUnica
  | PreguntaSeleccionMultiple
  | PreguntaAbierta;

export type PreguntaSeleccionUnica = {
  tipo: "SELECCION_UNICA";
  titulo: string;
  descripcion: string;
  adjuntos: AdjuntoMetadata[];
  urlAdjuntos: string[];
  opciones: OpcionPregunta[];
  opcion1?: OpcionPregunta;
  opcion2?: OpcionPregunta;
};

export type PreguntaSeleccionMultiple = {
  tipo: "SELECCION_MULTIPLE";
  titulo: string;
  descripcion: string;
  adjuntos: AdjuntoMetadata[];
  urlAdjuntos: string[];
  opciones: OpcionPregunta[];
};

export type PreguntaAbierta = {
  tipo: "PREGUNTA_ABIERTA";
  titulo: string;
  descripcion: string;
  adjuntos: AdjuntoMetadata[];
  urlAdjuntos: string[];
};

export type OpcionPregunta = {
  texto: string;
  esCorrecta: boolean;
};
/// OK

export type PreguntaEntregable = {
  tipo: "CARGA_ARCHIVO" | "CARGA_VIDEO";
  titulo: string;
  descripcion: string;
  adjuntos: AdjuntoMetadata[];
  urlAdjuntos: string[];
  accionPermitida: "ARCHIVOS" | "ENLACES";
};

export type Url = string;

export type AdjuntoMetadata = {
  tempId: string;
  nombreArchivo: string;
};

export interface Unidad {
  titulo: string;
  objetivo: string;
  contenido: InstanciaUnidadCursoContenido[];
}

export interface CursoModel {
  id?: string;
  imagenCurso?: File;
  urlImagen: string | null;
  nombre: string;
  descripcion: string;
  unidades: Unidad[];
}

export interface CursoPayload extends Omit<CursoModel, "imagenCurso"> {
  imagenCurso?: AdjuntoMetadata;
}

export type TipoDeContenido = "TEXTO" | "QUIZ" | "VIDEO" | "ENTREGABLE";
