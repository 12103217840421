import DOMPurify from "dompurify";
import "./contentViewer.styles.css";
import { lazy, Suspense, useMemo } from "react";

type ContentViewerProps = {
  textoHTML: string;
};

export const ContentViewer = ({ textoHTML }: ContentViewerProps) => {
  const ComponentCkEditor = useMemo(
    () => lazy(() => import("../../../../components/ckeditor/EditorTexto")),
    []
  );

  return (
    <Suspense fallback={<div>Cargando...</div>}>
      <ComponentCkEditor
        id="content-viewer"
        disabled={true}
        value={DOMPurify.sanitize(textoHTML)}
        ocultarBordes={true}
        ocultarToolbar={true}
      />
    </Suspense>
  );
};
