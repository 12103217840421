import { Box, Typography, alpha, styled } from "@mui/material";
// import bgTop from "../../../assets/HomeCourses/course_three.jpg";
import bgTop from "../../../assets/NumbersLocality/bannerCifrasMunicipio.jpg";
import theme from "../../../themes";
import ButtonSocialMedia from "../../../components/socialMedia/ButtonsSocialMedia";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  height: "70vh",
  backgroundImage: `url(${bgTop})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "fit-content",
    height: 500,
  },
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 1.5em 1.5em 1.5em",
  backgroundColor: alpha(theme.palette.info.dark, 0.85),
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

// const StyledButton = styled(Button)(({ theme }) => ({
//   textTransform: "uppercase",
//   fontFamily: "Barlow",
//   fontSize: 14,
//   backgroundColor: theme.palette.primary.main,
//   borderRadius: "8px",
//   color: theme.palette.secondary.active,
//   "&:hover": {
//     backgroundColor: theme.palette.primary.hover,
//     color: theme.palette.common.white,
//   },
//   opacity: 1,
// }));

// const StyledWhiteButton = styled(Button)(({ theme }) => ({
//   textTransform: "uppercase",
//   fontFamily: "Barlow",
//   fontSize: 14,
//   borderRadius: "8px",
//   backgroundColor: alpha(theme.palette.common.white, 0.85),
//   color: theme.palette.secondary.active,
//   "&:hover": {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.secondary.hover,
//   },
//   opacity: 1,
// }));

const MainBanner = () => {
  return (
    <StyledBox display="flex" justifyContent="center">
      <ButtonSocialMedia right={30} />
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledDivMediaCaption>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            sx={{ height: "100%", margin: 0, padding: 0 }}
          >
            <Box mb={2}>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: 24,
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                  lineHeight: 2,
                }}
              >
                MUNICIPIO EN DATOS
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: 42,
                  fontWeight: 400,
                  color: theme.palette.common.white,
                  lineHeight: 1,
                }}
              >
                Información útil para mejores decisiones
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                Datos sobre la situación de la violencia hacia las mujeres y
                sobre acciones que realizan los municipios participantes del
                Proyecto Vida Digna Sin Violencia, una línea de base o
                información de partida, así como, avances de cada municipio en
                la prevención, atención y promoción de las autonomías de las
                mujeres.
              </Typography>
            </Box>

            {/* <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              mt={"1rem"}
              gap={1.5}
              alignItems={"start"}
            >
              <StyledWhiteButton
                variant="contained"
                onClick={() => console.log("go to comparadores")}
              >
                COMPARADOR
              </StyledWhiteButton>
              <StyledButton
                variant="contained"
                onClick={() => console.log("go to estadisticas")}
              >
                ESTADÍSTICAS
              </StyledButton>
            </Box> */}
          </Box>
        </StyledDivMediaCaption>
      </Box>
    </StyledBox>
  );
};

export default MainBanner;
