import { useHistory, useLocation } from "react-router-dom";
import {
  useCampaignContext,
  withCampaignContextProvider,
} from "../campaign/context";
import { useCallback, useEffect, useState } from "react";
import { useGetResourceById, useUpdateResource } from "./data";
import { printLog } from "../../../shared/utils";
import { Alerts } from "../../../shared/hooks/useAlert";
import React from "react";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { DesignSystem } from "../../../themes/theme";
import { DetailCampaignGeneral } from "../campaign/components/DetailCampaignGeneral";
import { DetailCampaignResources } from "../campaign/components/DetailCampaignResources";
import { NeutralButton, PrimaryButton } from "../campaign/shared/Elements";

const DetailResourcePage = () => {
  const navigate = useHistory();
  const location = useLocation();
  const myState: any = location.state;
  const [campaign, dispatch] = useCampaignContext();

  const [loading, setLoading] = useState<boolean>(false);

  const getCampaign = useGetResourceById();
  const updateCampaign = useUpdateResource();

  const fetchCampaign = useCallback(async (campaignId: string) => {
    setLoading(true);
    const fetchedCampaign = await getCampaign(campaignId);
    printLog("Campaign fetched :: ", fetchedCampaign);
    dispatch({ type: "LOAD_CAMPAIGN", campaign: fetchedCampaign });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myState.campaignId) {
      fetchCampaign(myState.campaignId);
    }
  }, [fetchCampaign, myState.campaignId]);

  const handleCloseDetalle = () => {
    navigate.goBack();
  };

  const handleSaveCampaign = async () => {
    printLog("recurso >> ", campaign);
    const { titulo, descripcion, recursos } = campaign;
    const errores = [];
    if (!titulo || titulo.trim() === "") {
      errores.push("El recurso debe tener un título");
    }
    if (!descripcion || descripcion.trim() === "") {
      errores.push("El recurso debe tener una descripción");
    }
    if (!recursos || recursos.length === 0) {
      errores.push("El recurso debe tener al menos un recurso");
    }
    printLog("errrores > ", errores);
    if (errores.length) {
      Alerts.showError({
        description: errores.join("."),
      });
      return;
    }

    if (!campaign.id) {
      Alerts.showError({
        description: "No se pudo encontrar el recurso para editar",
      });
      return;
    }

    setLoading(true);

    printLog("Actualizar recurso.... ", campaign);
    const response = await updateCampaign({ ...campaign }, campaign.id);
    setLoading(false);
    if (response) {
      Alerts.showSuccess({
        description: "Recurso actualizado correctamente",
      });
      handleCloseDetalle();
    }
  };

  return (
    <React.Fragment>
      <Card elevation={0} sx={{ borderRadius: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          alignContent="center"
          sx={{
            borderBottom: "1px solid #cacaca",
            paddingX: "2rem",
            paddingY: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton size="small" onClick={handleCloseDetalle}>
              <KeyboardBackspaceRounded />
            </IconButton>
            <Typography
              sx={{ ...DesignSystem?.titulo1, flex: 1, fontWeight: 500 }}
            >
              Otro recurso
            </Typography>
          </Box>
        </Box>
        {loading && <LinearProgress />}
        <Box sx={{ paddingX: "2rem", pb: 4 }}>
          <DetailCampaignGeneral loading={loading} tipo={"RESOURCES"} />
          <Box my={2} />
          <Divider />
          <DetailCampaignResources loading={loading} tipo={"RESOURCES"} />
          <Box display="flex" gap={2} justifyContent="flex-end" mt={3}>
            <NeutralButton disabled={loading} onClick={handleCloseDetalle}>
              Cancelar
            </NeutralButton>
            <PrimaryButton
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={20} color="inherit" />
              }
              onClick={handleSaveCampaign}
            >
              Guardar
            </PrimaryButton>
          </Box>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default withCampaignContextProvider(DetailResourcePage);
