import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { MoreHoriz } from "@mui/icons-material";

type DynamicObject<Key extends string, Value> = {
  [K in Key]: Value;
};

type BreadcrumbTypes = {
  routes: {
    name: string;
    path: string;
    param?: DynamicObject<string, string | number>;
  }[];
};

const Breadcrumb = ({ routes }: BreadcrumbTypes) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleOpenMenu = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleClick = (
    path: string,
    param?: DynamicObject<string, string | number>
  ) => {
    history.push(path, param);
  };

  if (isMobile) {
    const first = routes[0];
    const last = routes[routes.length - 1];
    const middleRoutes = routes.slice(1, -1);

    return (
      <Breadcrumbs aria-label="breadcrumb">
        {/* Primer elemento */}
        <Link
          color="inherit"
          underline="hover"
          onClick={() => handleClick(first.path)}
          style={{ cursor: "pointer" }}
        >
          {first.name}
        </Link>

        {/* Menú desplegable para los intermedios */}
        {middleRoutes.length > 0 && (
          <Box>
            <IconButton size="small" onClick={handleOpenMenu}>
              <MoreHoriz fontSize="small" sx={{mt: 1}} />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleCloseMenu}
            >
              {middleRoutes.map((route) => (
                <MenuItem
                  key={route.path}
                  onClick={() => handleClick(route.path)}
                >
                  {route.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}

        {/* Último elemento */}
        <Typography
          sx={{
            color: theme.palette.courseSecondary.main,
            fontSize: 16,
            fontWeight: 800,
          }}
        >
          {last.name}
        </Typography>
      </Breadcrumbs>
    );
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {routes.map((route, index) => {
        const isLast = index === routes.length - 1;

        return isLast ? (
          <Typography
            key={route.path}
            sx={{
              color: theme.palette.courseSecondary.main,
              fontSize: 16,
              fontWeight: 800,
            }}
          >
            {route.name}
          </Typography>
        ) : (
          <Link
            key={route.path}
            color="inherit"
            underline="hover"
            onClick={() => handleClick(route.path, route.param)}
            style={{ cursor: "pointer" }}
          >
            {route.name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
