import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  styled,
  Typography,
} from "@mui/material";
import { FacebookRounded } from "@mui/icons-material";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../../../../components/Layout";
import theme from "../../../../themes";
import Footer from "../../../../components/Footer";
import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import useAppContext from "../../../../shared/hooks/useAppContext";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import moment from "moment";
import { isJsonString } from "../../../../shared/utils";
import draftToHtml from "draftjs-to-html";
import "./../../../styles/ckeditor.css";

interface RouteParams {
  id: string;
}

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  // backgroundColor:'#e9e9e9'
}));

const StyledButton2 = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  border: `1px solid ${theme.palette.primary.main}`,
  height: "40px",
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: "#808080",
    color: "#E8E8E8",
  },
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
}));

const StyledImg = styled("img")(() => ({
  maxWidth: "100%",
  height: "100%",
  objectFit: "contain",
}));

type NewsContent = {
  noticia_cod: number;
  contenido: string;
  titulo: string;
  ultimaModificacionFecha: string;
  urlImagen: string;
  alcanceGeografico: string;
  esNoticiaSolidar: boolean;
  categoria: string;
  esDestacada: true;
};

const NewsDetail = () => {
  const { pathname, hash, key } = useLocation();
  const params = useParams<RouteParams>();

  const ComponentCkEditor = useMemo(
    () => lazy(() => import("../../../../components/ckeditor/EditorTexto")),
    []
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getNewsContent, newsContent } = useAppContext();
  const [convertedContent, setConvertedContent] = useState<string>();

  const typedNewsContent: NewsContent = newsContent;

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }

    window.scrollTo(0, 0);
  }, [pathname, hash, key]);

  useEffect(() => {
    // Required to have just one _
    //let index = params.id.indexOf('_');
    //let linkId:number=0;

    //if(index !== -1) {
    let linkId = parseInt(params.id);
    //}

    if (linkId) getNewsContent(linkId);
  }, []);

  useEffect(() => {
    if (typedNewsContent) {
      setIsLoading(false);
      if (
        isJsonString(typedNewsContent.contenido) &&
        JSON.parse(typedNewsContent.contenido)
      ) {
        const parseado = JSON.parse(typedNewsContent.contenido);
        const editorContent = EditorState.createWithContent(
          convertFromRaw(parseado)
        );
        const rawContentState = convertToRaw(editorContent.getCurrentContent());
        let markup = draftToHtml(rawContentState);
        markup = markup.replace(/(width\s*:\s*auto)/gi, "width: 100%");
        setConvertedContent(markup);
      } else {
        if (
          typedNewsContent.contenido === "" ||
          typedNewsContent.contenido.trim().length === 0
        ) {
          setConvertedContent("<p>Sin contenido</p>");
        } else {
          setConvertedContent(typedNewsContent.contenido);
        }
      }
    }
  }, [typedNewsContent]);

  const handleFacebookShare = () => {
    if (typedNewsContent && typedNewsContent.titulo) {
      const url = `https://www.facebook.com/sharer/sharer.php?u=https://vidadignasinviolencia.org.bo/hemeroteca/noticia/${typedNewsContent.noticia_cod}`;
      window.open(url, "_blank");
    }
  };

  const handleTwitterShare = () => {
    if (typedNewsContent && typedNewsContent.titulo) {
      const url = `https://twitter.com/intent/tweet?url=https://vidadignasinviolencia.org.bo/hemeroteca/noticia/${typedNewsContent.noticia_cod}`;
      window.open(url, "_blank");
    }
  };

  return (
    <Layout>
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          color: "#fff",
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <StyledBox display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="center"
          sx={{ width: "100%", backgroundColor: "#e9e9e9" }}
        >
          <Box
            display={"flex"}
            flexDirection="column"
            sx={{
              width: { xs: "100%", md: "75%" },
              height: "100%",
              margin: 0,
              padding: "3em 1.5em 3em 1.5em",
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              sx={{ width: "100%", backgroundColor: "#e9e9e9" }}
            >
              <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
                <Box display="flex">
                  <Chip
                    label={"NOTICIA"}
                    sx={{
                      backgroundColor: "#818181",
                      color: "#FFFFFF",
                      fontSize: { xs: 9, md: 13 },
                    }}
                  />
                  {typedNewsContent && typedNewsContent.esNoticiaSolidar ? (
                    <Chip
                      label={"SOLIDAR SUIZA"}
                      sx={{
                        ml: "1em",
                        backgroundColor: "#fb0d0d",
                        color: "#FFFFFF",
                      }}
                    />
                  ) : null}
                </Box>
                <Box my={"0.5em"}>
                  <Typography
                    sx={{
                      fontFamily: "Barlow",
                      fontSize: 42,
                      lineHeight: 1,
                      color: "#54595F",
                      fontWeight: "bold",
                      [theme.breakpoints.down("md")]: {
                        fontSize: 22,
                      },
                    }}
                  >
                    {typedNewsContent ? typedNewsContent.titulo : ""}
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.neutral.hover,
                      fontSize: "14px",
                      margin: "1.5em 0 1.5em 0",
                    }}
                  >
                    {typedNewsContent
                      ? `${moment(
                          typedNewsContent.ultimaModificacionFecha
                        ).format("DD-MM-YYYY")}`
                      : ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" sx={{ width: "100%" }}>
              <Box
                display="inline-block"
                height={"100%"}
                width={"100%"}
                style={{ padding: "0em 0 0 0" }}
              >
                {typedNewsContent && typedNewsContent.urlImagen ? (
                  <StyledImg src={typedNewsContent.urlImagen} alt="img" />
                ) : null}
              </Box>
            </Box>
            <Box display="flex" sx={{ width: "100%" }}>
              <Suspense fallback={<div>Cargando...</div>}>
                <ComponentCkEditor
                  id="editor-cursos"
                  disabled={true}
                  value={convertedContent}
                  ocultarBordes={true}
                  ocultarToolbar={true}
                />
              </Suspense>
            </Box>
            {/*  */}

            {/* Corregir la parte de abajo */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {/* <Box display="flex" sx={{ width: { xs: "100%", md: "75%" } }}>
                <Box
                  display="flex"
                  sx={{ width: "100%", paddingBottom: "1.5em" }}
                ></Box>
              </Box> */}
              <Box display="flex" sx={{ width: { xs: "100%" } }}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  sx={{
                    width: "100%",
                    borderTop: `1px solid ${theme.palette.neutral.main}`,
                  }}
                >
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{ py: "1em", width: "100%" }}
                    px={2}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        flex: 1,
                        fontFamily: "Ubuntu",
                        fontSize: { xs: "18px", md: "22px" },
                        color: theme.palette.neutral.main,
                      }}
                    >
                      CATEGORÍA
                    </Typography>
                    <StyledButton2 variant="text">
                      {typedNewsContent && typedNewsContent.categoria
                        ? typedNewsContent.categoria
                        : ""}
                    </StyledButton2>
                  </Box>
                  <Box
                    display="flex"
                    sx={{
                      borderLeft: {
                        xs: "none",
                        md: `1px solid ${theme.palette.neutral.main}`,
                      },
                      py: "1em",
                    }}
                    px={2}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: "16px",
                        color: theme.palette.neutral.main,
                      }}
                    >
                      Última actualización:&nbsp;
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: "16px",
                        color: theme.palette.neutral.main,
                      }}
                    >
                      {typedNewsContent &&
                      typedNewsContent.ultimaModificacionFecha
                        ? moment(
                            typedNewsContent.ultimaModificacionFecha
                          ).format("DD-MM-YYYY HH:mm")
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* <Box display="flex" sx={{ width: { xs: "100%", md: "100%" } }}>
              </Box> */}
              <Box
                display="flex"
                sx={{
                  width: "100%",
                  borderTop: `1px solid ${theme.palette.neutral.main}`,
                }}
                pt={2}
                px={2}
              >
                <Box display="flex" gap={1.5} sx={{ width: { xs: "100%" } }}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: { xs: "18px", md: "22px" },
                      color: theme.palette.neutral.main,
                    }}
                  >
                    Comparte en
                  </Typography>
                  <Chip
                    icon={<FacebookRounded color="inherit" fontSize="small" />}
                    label="Facebook"
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: 500,
                      background: "#004c89",
                      color: theme.palette.common.white,
                    }}
                    onClick={handleFacebookShare}
                  />
                  <Chip
                    icon={<TwitterIcon color="inherit" fontSize="small" />}
                    label="Twitter"
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: 500,
                      background: "#00b8ee",
                      color: theme.palette.common.white,
                    }}
                    onClick={handleTwitterShare}
                  />
                </Box>
              </Box>
            </Box>
            {/*  */}
          </Box>
        </Box>
      </StyledBox>
      <Footer />
    </Layout>
  );
};

export default NewsDetail;
