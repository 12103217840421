import {
  ArrowBackRounded,
  ArrowForwardRounded,
  KeyboardBackspace,
} from "@mui/icons-material";
import {
  Box,
  Button,
  MobileStepper,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { printLog, isJsonString } from "../../../shared/utils";
import { ContentViewer } from "../../../shared/components/curso/content/ContentViewer";
import { YoutubePlayer } from "../../../shared/components/curso/YoutubePlayer";
import { QuizViewer } from "../../../shared/components/curso/QuizViewer";
import { CourseNeutralButton } from "../shared/Elements";
import {InstanciaUnidadCursoContenido, Unidad} from "../interface";
import {QuizFinishedEvent} from "../../../shared/components/curso/CursoViewerTypes";

type UnidadVistaPreviaProps = {
  unidad: Unidad;
  onClose: () => void;
};

export const UnidadVistaPrevia = ({
  unidad,
  onClose,
}: UnidadVistaPreviaProps) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [contenidoUnidad, setContenidoUnidad] = useState<InstanciaUnidadCursoContenido | null>(null);

  const maxSteps = unidad !== null ? unidad.contenido.length : 0;
  printLog("maxSteps :: ", maxSteps);

  useEffect(() => {
    setContenidoUnidad(unidad.contenido[activeStep]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const handleBack = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setContenidoUnidad(unidad.contenido[activeStep - 1]);
  };

  const convertedContent = (texto: string) => {
    printLog("Texto a convertir en html :: ", texto);
    if (isJsonString(texto) && JSON.parse(texto)) {
      const parseado = JSON.parse(texto);
      const editorContent = EditorState.createWithContent(
        convertFromRaw(parseado)
      );
      const rawContentState = convertToRaw(editorContent.getCurrentContent());
      let markup = draftToHtml(rawContentState);
      // Validando estilos con ancho y alto con valor auto
      markup = markup.replace(/(width\s*:\s*auto)/gi, "width: 100%");
      // markup = markup.replace(/(height\s*:\s*auto)/ig, "height: auto");
      // printLog('markup resultado :: ', markup)
      // setConvertedContent(convertidoHTML)
      return markup;
    } else {
      if(texto.trim() !== "") {
        return texto;
      }
      return "<p>Sin contenido</p>";
    }
  };
  const handleFinalizarQuiz = (data: QuizFinishedEvent) => {
    // printLog('finalizando quiz ::', data)
    return;
  };

  const handleNext = async () => {
    if (activeStep < maxSteps) {
      const siguientePaso = activeStep + 1;
      setActiveStep(siguientePaso);
      setContenidoUnidad(unidad.contenido[siguientePaso]);
    }
  };

  return (
    <>
      <Box display="flex" my={2} alignItems="center">
        <CourseNeutralButton onClick={onClose}>
          <KeyboardBackspace />
        </CourseNeutralButton>
        {unidad !== null && (
          <Typography
            sx={{
              flex: 1,
              fontSize: "1.3rem",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Unidad: {unidad.titulo}
          </Typography>
        )}
      </Box>
      {unidad !== null &&
        unidad.contenido !== null &&
        unidad.contenido.length > 0 && (
          <>
            <MobileStepper
              variant="dots"
              steps={unidad.contenido.length}
              position="static"
              activeStep={activeStep}
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                "& .MuiMobileStepper-dotActive": {
                  background: theme.palette.info.main,
                },
              }}
              nextButton={null}
              backButton={null}
            />
            {/* <Box display="flex" justifyContent="center" my={2}>
        { unidad.contenido.map((c: any, idxC: number) => (
          <Box key={idxC} sx={{
            width: '60px',
            height: '8px',
            borderRadius: '4px',
            mx: 1,
            backgroundColor: idxC === activeStep ? 'blue' : '#cacaca'
          }}></Box>
        )) }
      </Box> */}
            <Box sx={{ mt: 1 }}>
              <Box
                flex="1"
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                sx={{
                  px: 1,
                  py: 2,
                  height: "calc(100vh - 350px)",
                  overflowY: "scroll",
                  border: `1px solid ${theme.palette.neutral.main}`,
                  borderRadius: "10px",
                  m: 2,
                  position: "relative",
                  [theme.breakpoints.down("md")]: {
                    px: 1,
                  },
                }}
              >
                <Typography sx={{ alignSelf: "center", mb: 1 }}>
                  {contenidoUnidad !== null && contenidoUnidad
                    ? contenidoUnidad.titulo
                    : ""}
                </Typography>
                <Box sx={{ height: "100%" }}>
                  {/* { JSON.stringify(unidadCurso.contenidos[activeStep - 1]) ?? '' } */}
                  {/* { JSON.stringify(contenidoUnidad) ?? '' } */}
                  {contenidoUnidad !== null &&
                    contenidoUnidad.tipo === "TEXTO" &&
                    contenidoUnidad.texto !== null && (
                      <ContentViewer
                        textoHTML={convertedContent(contenidoUnidad.texto)}
                      />
                    )}
                  {contenidoUnidad !== null &&
                    contenidoUnidad.tipo === "VIDEO" && (
                      <YoutubePlayer urlVideo={contenidoUnidad.link!} />
                    )}
                  {contenidoUnidad != null &&
                    contenidoUnidad.tipo === "QUIZ" && (
                      <>
                        <QuizViewer
                          idxContenido={activeStep}
                          tipo={contenidoUnidad.tipo}
                          isEvaluated={contenidoUnidad.isEvaluated}
                          preguntas={contenidoUnidad.preguntas}
                          onFinalizarQuiz={handleFinalizarQuiz}
                          actionsPosition="static"
                          onCancel={() => { activeStep <= 0 ? onClose() : handleBack() }}
                        />
                      </>
                    )}
                  {contenidoUnidad !== null &&
                    contenidoUnidad.tipo === "ENTREGABLE" && (
                      <>
                        <QuizViewer
                          idxContenido={activeStep}
                          tipo={contenidoUnidad.tipo}
                          preguntas={contenidoUnidad.preguntas}
                          onFinalizarQuiz={handleFinalizarQuiz}
                        />
                      </>
                    )}
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box
                  component={Button}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                    color: "white",
                    borderRadius: "8px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    "&:disabled": {
                      background: "transparent",
                      border: `1px solid ${theme.palette.neutral.main}`,
                    },
                    [theme.breakpoints.down("md")]: {
                      py: 1.5,
                      pl: 0.5,
                      pr: 0,
                    },
                  }}
                >
                  <ArrowBackRounded />
                </Box>
                <Box
                  component={Button}
                  disabled={activeStep === maxSteps - 1}
                  onClick={handleNext}
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                    color: "white",
                    borderRadius: "8px",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    "&:disabled": {
                      background: "transparent",
                      border: `1px solid ${theme.palette.neutral.main}`,
                    },
                    [theme.breakpoints.down("md")]: {
                      py: 1.5,
                      pl: 0.5,
                      pr: 0,
                    },
                  }}
                >
                  <ArrowForwardRounded />
                </Box>
              </Box>
            </Box>
          </>
        )}
    </>
  );
};
