import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  styled,
  Grid,
  Container,
  Skeleton,
} from "@mui/material";
import { printLog } from "../../../shared/utils";
import useHttp from "../../../shared/hooks/useHttp";

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "8px",
  color: theme.palette.secondary.active,
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: theme.palette.common.white,
  },
  opacity: 1,
}));

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#E0E0E0",
  borderRadius: "12px",
  overflow: "hidden",
  padding: "20px",
  margin: "20px 0",
}));

const StyledImg = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  height: "auto",
  objectFit: "cover",
  transition: "transform 0.3s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const handleClick = (url: string) => {
  printLog("Descargar guía");
  // window.open("Guía EduActivistas 2024.pdf", "_blank");
  window.open(url, "_blank");
};

type BannerDestacado = {
  id: string;
  titulo: string;
  urlRecurso: string | null;
  esDestacado: boolean;
  urlAdjunto: string;
  urlMiniatura: string;
};

export const FeaturedBanner = () => {
  const http = useHttp();

  const [banner, setBanner] = React.useState<BannerDestacado | null>(null);

  const [loading, setLoading] = React.useState<boolean>(false);

  const getBanner = async () => {
    setLoading(true);
    try {
      const response = await http.get("/comunicacion/banner/destacado", {
        withFiles: true,
      });
      if (response) {
        setBanner(response as BannerDestacado);
      }
    } catch (error) {
      printLog("Error al obtener banners >>> ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height="300px" />
        </Box>
      ) : (
        banner && (
          <StyledBox id="featuredBanner">
            <Grid container alignItems="center">
              <Grid item xs={12} md={8} sx={{ padding: "2em" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={{ xs: "center", md: "start" }}
                >
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    color="rgb(74,12,85,0.8)"
                    textAlign={{ xs: "center", md: "left" }}
                    gutterBottom
                  >
                    {banner?.titulo}
                    {/* Descarga nuestra última guía de trabajo: EDUACTIVISTAS */}
                  </Typography>
                  {(banner?.urlAdjunto || banner?.urlRecurso) && (
                    <StyledButton
                      variant="contained"
                      onClick={() =>
                        handleClick(
                          banner?.urlAdjunto || banner?.urlRecurso || ""
                        )
                      }
                    >
                      VER MÁS
                    </StyledButton>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    overflow: "hidden",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                >
                  <StyledImg
                    src={
                      banner?.urlMiniatura
                        ? banner.urlMiniatura
                        : require("./../../../assets/Communication/featured/featured-guia.png")
                    }
                    alt="Portada de la guía EDUACTIVISTAS"
                    onClick={() =>
                      handleClick(
                        banner?.urlAdjunto || banner?.urlRecurso || ""
                      )
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </StyledBox>
        )
      )}
    </Container>
  );
};
