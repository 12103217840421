import { printLog } from "../../../../shared/utils";
import { Campaign } from "../interface";
import { useAuthContext } from "../../../../shared/auth/AuthContext";
import useHttp from "../../../../shared/hooks/useHttp";

type ResponseListCampaigns = {
  id: string;
  title: string;
  description: string;
  esPrincipal: boolean;
}[];

type ResponseCampaign = {
  id: string;
  title: string;
  description: string;
  resources: {
    id: string;
    title: string;
    url: string;
    type: string;
    imageFile: {
      id: string;
      nombre: string;
      url: string;
    };
    adjuntoFile?: {
      id: string;
      nombre: string;
      url: string;
    };
  }[];
  esPrincipal: boolean;
};

export function useGetCampaigns(): () => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async () => {
    try {
      const response = await http.get("/admin/campaign", {
        auth: authContext?.user?.token ?? undefined,
      });
      if (response) {
        return (response as ResponseListCampaigns).map((item) => ({
          titulo: item.title,
          id: item.id,
          descripcion: item.description,
          esPrincipal: item.esPrincipal,
        }));
      } else {
        return [];
      }
    } catch (error) {
      printLog("Error al obtener las campañas >> ", error);
      return [];
    }
  };
}

export function useGetCampaignById(): (campaignId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (campaignId: string) => {
    try {
      const response = await http.get(`/admin/campaign/${campaignId}`, {
        auth: authContext?.user?.token ?? undefined,
      });
      const data: ResponseCampaign = response as any;
      return {
        id: data.id,
        titulo: data.title,
        descripcion: data.description,
        recursos: data.resources,
        esPrincipal: data.esPrincipal,
      };
    } catch (error) {
      printLog("Error al obtener la campaña >> ", error);
      return null;
    }
  };
}

export function useCreateCampaign(): (payload: Campaign) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (payload: Campaign) => {
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        ...payload,
        tipo: 'PRINCIPAL',
        recursos: payload.recursos?.map((recurso) => ({
          tipo: recurso.tipo,
          url: recurso.url,
          titulo: recurso.titulo,
          image: {
            name: recurso.adjunto?.name,
            type: recurso.adjunto?.type,
            size: recurso.adjunto?.size,
          },
        })),
      })
    );

    payload.recursos?.forEach((recurso) => {
      if (recurso.adjunto) {
        formData.append("images", recurso.adjunto);
      }
    });

    printLog("[create:campaign] form data >> ", formData);
    try {
      return await http.post("/admin/campaign", {
        payload: formData,
        auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
    } catch (error: unknown) {
      // if (axios.isAxiosError(error)) {
      printLog("Error al crear la campaña >> ", error);
      return null;
      // }
    }
  };
}

export function useUpdateCampaign(): (
  payload: Campaign,
  campaignId: string
) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (payload: Campaign, campaignId: string) => {
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        ...payload,
        tipo: 'PRINCIPAL',
        recursos: payload.recursos?.map((recurso) => ({
          id: recurso.id,
          tipo: recurso.tipo,
          url: recurso.url,
          titulo: recurso.titulo,
          image: {
            name: recurso.adjunto?.name,
            type: recurso.adjunto?.type,
            size: recurso.adjunto?.size,
          },
        })),
      })
    );

    payload.recursos?.forEach((recurso) => {
      if (recurso.adjunto) {
        formData.append("images", recurso.adjunto);
      }
    });

    printLog("[create:campaign] form data >> ", formData);
    try {
      return await http.put(`/admin/campaign/${campaignId}`, {
        payload: formData,
        auth: authContext?.user?.token ?? undefined,
        withFiles: true,
      });
    } catch (error: unknown) {
      printLog("Error al crear la campaña >> ", error);
      return null;
    }
  };
}

export function useDeleteCampaign(): (campaignId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (campaignId: string) => {
    try {
      return await http.delete(`/admin/campaign/${campaignId}`, {
        auth: authContext?.user?.token ?? undefined,
      });
    } catch (error) {
      printLog("Error al eliminar la campaña >> ", error);
    }
  };
}
