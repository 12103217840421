import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";
import CInputForm from "../../../../shared/components/form/CInputForm";
import { useCampaignContext } from "../context";

export const DetailCampaignGeneral = ({
  loading,
  tipo,
}: {
  loading: boolean;
  tipo: "RESOURCES" | "CAMPAIGNS";
}) => {
  const [campaign, dispatch] = useCampaignContext();
  const handleInputChange =
    (prop: "titulo" | "descripcion") =>
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: "EDIT_CAMPAIGN",
        payload: {
          [prop]: target.value,
        },
      });
    };

  return (
    <React.Fragment>
      <Box
        sx={{ flexGrow: 1 }}
        p={{
          xs: 0,
          sm: 0,
          md: 3,
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box display="flex">
            <Typography variant="h6" fontWeight={"bold"}>
              DATOS GENERALES{" "}
              {tipo === "CAMPAIGNS" ? "DE LA CAMPAÑA" : "DEL RECURSO"}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            px={{
              xs: 0,
              sm: 0,
              md: 5,
            }}
            pt={{
              xs: 0,
              sm: 0,
              md: 5,
            }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item container xs={12} md={6}>
                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                  <Typography component={"span"} fontWeight={"bold"}>
                    Título
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <CInputForm
                    type="text"
                    placeholder="Escribe el título de la campaña"
                    name="titulo"
                    value={campaign.titulo}
                    onChange={handleInputChange("titulo")}
                    disabled={loading}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} md={6}>
                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                  <Typography component={"span"} fontWeight={"bold"}>
                    Descripción
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <CInputForm
                    type="text"
                    placeholder="Escribe la descripción de la campaña"
                    multiline
                    rows={3}
                    name="descripcion"
                    value={campaign.descripcion}
                    onChange={handleInputChange("descripcion")}
                    disabled={loading}
                  />
                </Grid>
              </Grid>

              {tipo === "CAMPAIGNS" && (
                <Grid item container xs={12}>
                  <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                    <Typography component={"span"} fontWeight={"bold"}>
                      Es principal
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={campaign.esPrincipal}
                      ref={null}
                      onChange={(event) => {
                        dispatch({
                          type: "EDIT_CAMPAIGN",
                          payload: {
                            esPrincipal: event.target.checked,
                          },
                        });
                      }}
                      disabled={loading}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};
