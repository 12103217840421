import produce from "immer";
import {
  TipoPregunta,
  TipoPreguntaEntregable,
  TipoPreguntaQuiz,
} from "../../../interface";
import {
  AdjuntoNuevo,
  CategoriaPregunta,
  PreguntaEntregableModel,
  PreguntaModel,
  PreguntaQuizModel,
  PreguntaVacia,
} from "../../../types";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { printLog } from "../../../../../shared/utils";

export type PreguntasHandlerProps<C extends CategoriaPregunta> = {
  preguntas?: C extends "QUIZ"
    ? PreguntaQuizModel[]
    : PreguntaEntregableModel[];
  onPreguntasChange: (
    preguntas: C extends "QUIZ"
      ? PreguntaQuizModel[]
      : PreguntaEntregableModel[]
  ) => void;
  onAdjuntosChange: (tempId: string, file: File) => void;
  onAdjuntosDelete: (tempId: string) => void;
};

export function usePreguntasHandler<C extends CategoriaPregunta>(
  categoria: C,
  props: PreguntasHandlerProps<C>
) {
  const preguntas =
    props.preguntas && props.preguntas.length > 0
      ? props.preguntas
      : [crearPreguntaVacia(categoria)];
  const { onPreguntasChange, onAdjuntosChange, onAdjuntosDelete } = props;

  useEffect(() => {
    if (preguntas !== props.preguntas) {
      // Se inicializo la primera pregunta
      onPreguntasChange(preguntas);
    }
  });

  // https://immerjs.github.io/immer/update-patterns/
  const handleChangeTipoPregunta =
    (index: number) =>
    (tipo: C extends "QUIZ" ? TipoPreguntaQuiz : TipoPreguntaEntregable) => {
      printLog("Borrando adjunto: ", preguntas[index].id);
      onAdjuntosDelete(preguntas[index].id);
      onPreguntasChange(
        produce(preguntas, (draft) => {
          const nuevaPregunta = crearPreguntaConTipo(tipo);
          draft[index] = nuevaPregunta;
        })
      );
    };

  const handleChangePropPregunta =
    (index: number) => (prop: "titulo" | "descripcion", value: string) => {
      onPreguntasChange(
        produce(preguntas, (draft) => {
          draft[index][prop] = value;
        })
      );
    };

  const handleEliminarPregunta = (index: number) => () => {
    onAdjuntosDelete(preguntas[index].id);
    onPreguntasChange(
      produce(preguntas, (draft) => {
        draft.splice(index, 1);
      })
    );
  };

  const handleSetAdjunto = (index: number) => (adjunto?: AdjuntoNuevo) => {
    if (adjunto) {
      onAdjuntosChange(preguntas[index].id, adjunto.archivo);
    } else {
      onAdjuntosDelete(preguntas[index].id);
    }
    onPreguntasChange(
      produce(preguntas, (draft) => {
        draft[index].adjunto = adjunto;
      })
    );
  };

  const handleSetPregunta =
    (index: number) =>
    (
      pregunta: C extends "QUIZ" ? PreguntaQuizModel : PreguntaEntregableModel
    ) => {
      if (pregunta.adjunto && pregunta.adjunto.tipo === "NUEVO") {
        onAdjuntosChange(preguntas[index].id, pregunta.adjunto.archivo);
      } else {
        onAdjuntosDelete(preguntas[index].id);
      }
      onPreguntasChange(
        produce(preguntas, (draft) => {
          draft[index] = pregunta;
        })
      );
    };

  const addPregunta = () => {
    const nuevaPregunta = crearPreguntaVacia(categoria);
    onPreguntasChange(
      produce(preguntas, (draft) => {
        draft.push(nuevaPregunta);
      })
    );
  };
  return {
    preguntas,
    handleChangeTipoPregunta,
    handleChangePropPregunta,
    handleEliminarPregunta,
    handleSetAdjunto,
    handleSetPregunta,
    addPregunta,
  };
}
function crearPreguntaVacia(categoria: CategoriaPregunta): PreguntaVacia {
  return {
    id: uuidv4(),
    categoria,
    tipo: "",
    titulo: "",
    descripcion: "",
    adjunto: undefined,
  };
}

function crearPreguntaConTipo(
  tipo: TipoPreguntaQuiz | TipoPreguntaEntregable
): PreguntaModel {
  switch (tipo) {
    case TipoPregunta.SELECCION_UNICA: // radio
      return {
        id: uuidv4(),
        categoria: "QUIZ",
        tipo: tipo,
        titulo: "",
        descripcion: "",
        opciones: [
          {
            texto: "",
            esCorrecta: false,
          },
          {
            texto: "",
            esCorrecta: false,
          }
        ],
      };

    case TipoPregunta.SELECCION_MULTIPLE: // "checkbox"
      return {
        id: uuidv4(),
        categoria: "QUIZ",
        tipo: tipo,
        titulo: "",
        descripcion: "",

        opciones: [
          {
            texto: "",
            esCorrecta: false,
          },
        ],
      };

    case TipoPregunta.CARGA_ARCHIVO:
    case TipoPregunta.CARGA_VIDEO: // "checkbox"
      return {
        id: uuidv4(),
        categoria: "ENTREGABLE",
        tipo: tipo,
        titulo: "",
        descripcion: "",
        accionPermitida: "",
      };

    case TipoPregunta.PREGUNTA_ABIERTA: // "pregunta con texto abierto":
      return {
        id: uuidv4(),
        categoria: "QUIZ",
        tipo: tipo,
        titulo: "",
        descripcion: "",
      };
  }
  throw new Error("Tipo invalido");
}
