import produce from "immer";
import { Box, Grid, IconButton, Radio } from "@mui/material";
import React, { useState } from "react";
import CInputForm from "../../../../../shared/components/form/CInputForm";
import { PreguntaSeleccionUnica } from "../../../types";
import {
  AddCircleOutlineRounded,
  DeleteOutlineRounded,
} from "@mui/icons-material";

type UniqueSelectionEditorProps = {
  pregunta: PreguntaSeleccionUnica;
  onChangePregunta: (pregunta: PreguntaSeleccionUnica) => void;
};

const maxOptions = 5;
const minOptions = 2;

export function UniqueSelectionEditor({
  pregunta,
  onChangePregunta,
}: UniqueSelectionEditorProps) {
  const [opciones, setOpciones] = useState(() => {
    // Inicializa las opciones con las existentes o un array vacío
    const inicial = pregunta.opciones || [];
    const { opcion1, opcion2 } = pregunta;

    if (opcion1 && !inicial.find((opcion) => opcion.texto === opcion1.texto)) {
      inicial.push(opcion1);
    }
    if (opcion2 && !inicial.find((opcion) => opcion.texto === opcion2.texto)) {
      inicial.push(opcion2);
    }

    return inicial;
  });

  const handleRadioChange =
    (index: number) =>
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setOpciones(
          produce(opciones, (draft) => {
            draft.forEach((opcion, idx) => {
              opcion.esCorrecta = idx === index;
            });
          })
        );
      }
    };

  const handleTextChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const texto = event.target.value;
      setOpciones(
        produce(opciones, (draft) => {
          draft[index].texto = texto;
        })
      );
    };

  const handleAddOption = () => {
    if (opciones.length < maxOptions) {
      setOpciones(
        produce(opciones, (draft) => {
          draft.push({ texto: "", esCorrecta: false });
        })
      );
    }
  };

  const handleRemoveOption = (index: number) => {
    setOpciones(
      produce(opciones, (draft) => {
        draft.splice(index, 1);
      })
    );
  };

  // Sincronizar opciones con la pregunta
  React.useEffect(() => {
    onChangePregunta(
      produce(pregunta, (draft) => {
        draft.opciones = opciones;
      })
    );
  }, [opciones, onChangePregunta, pregunta]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={11}>
        <Box display="flex" flexDirection="column">
          {opciones.map((opcion, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <Radio
                checked={opcion.esCorrecta}
                onChange={handleRadioChange(index)}
              />
              <CInputForm
                name={`opcion${index + 1}`}
                placeholder="Introduzca la respuesta"
                value={opcion.texto}
                onChange={handleTextChange(index)}
              />
              {index === opciones.length - 1 && (
                <IconButton
                  size="small"
                  sx={{
                    fill: "green",
                  }}
                  disabled={opciones.length >= maxOptions}
                  onClick={handleAddOption}
                >
                  <AddCircleOutlineRounded />
                </IconButton>
              )}
              <IconButton
                disabled={opciones.length <= minOptions}
                sx={{ fill: "red" }}
                onClick={() => handleRemoveOption(index)}
              >
                <DeleteOutlineRounded />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}
