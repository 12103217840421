import { KeyboardBackspaceRounded as KeyboardBackspaceRoundedIcon } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { DesignSystem } from "../../themes/theme";
import { useAuthContext } from "../../shared/auth/AuthContext";
import { SystemRoles } from "../../shared/common/constants";
import { CoursePrimaryButton } from "../Courses/shared/Elements";
import { CifraFormDialog } from "./components/CifraFormDialog";

export const AdminCifrasPage = () => {
  const navigate = useHistory();
  const { user } = useAuthContext();
  const [cifraDialog, setCifraDialog] = useState<boolean>(false);

  const abrirModalCifras = () => setCifraDialog(true);
  const cerrarModalCifras = () => setCifraDialog(false);

  return (
    <React.Fragment>
      {cifraDialog && (
        <CifraFormDialog
          open={cifraDialog}
          onClose={cerrarModalCifras}
          // onSubmitNoticia={onSubmitNoticia}
          // loading={loading}
          // media={media}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={6}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate.push("/", {
                replace: true,
              })
            }
          >
            <KeyboardBackspaceRoundedIcon />
          </IconButton>
          <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
            Municipio en datos
          </Typography>
        </Box>
        {user?.rol !== SystemRoles.PARTICIPANTE && (
          <Box>
            <CoursePrimaryButton onClick={abrirModalCifras}>
              Agregar +
            </CoursePrimaryButton>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};
