import { Box, Grid, Typography, styled } from "@mui/material";
import { ResourceCard } from "./ResourceCard";
import useHttp from "../../../shared/hooks/useHttp";
import { printLog } from "../../../shared/utils";
import { useEffect } from "react";
import React from "react";

type ResponseResources = {
  id: string;
  title: string;
  description: string;
  urlImage: string;
  resources: {
    id: string;
    titulo: string;
    url: string;
    tipo: "VIDEO" | "ARCHIVO" | "IMAGEN";
    urlImage: string;
    urlAttachment?: string;
  }[];
};

const StyledBox = styled(Box)(() => ({
  margin: "0 0 0 0",
  padding: 0,
  width: "100%",
  // backgroundColor: "#fcf8de",
}));

const colors = ["#48974d", "#742394", "#e19e35", "#5a9ec7"];

export const TipoBloque = {
  REDES_LUCHA: "REDES_LUCHA",
  MUJERES: "MUJERES",
  OTRAS_MASCULINIDADES: "OTRAS_MASCULINIDADES",
  EDUACTIVISTAS: "EDUACTIVISTAS",
};

export const ResourcesSection = () => {
  const http = useHttp();

  const [resources, setResources] = React.useState<ResponseResources[]>([]);

  const fetchResource = async () => {
    try {
      const response = await http.get(
        "/comunicacion/campaign/others-resources",
        {
          auth: undefined,
          withFiles: true,
        }
      );
      printLog("response", response);
      if (response) {
        setResources(response as ResponseResources[]);
      }
    } catch (error) {
      printLog("error", error);
    }
  };

  useEffect(() => {
    fetchResource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledBox display="flex" justifyContent="center" id="campaign">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          margin: 0,
          padding: 0,
          pt: 5,
          px: 3,
          pb: 10,
        }}
      >
        <Typography
          variant="h4"
          textAlign={"center"}
          fontWeight={"bold"}
          color={"rgb(74,12,85,0.8)"}
        >
          OTROS RECURSOS DE COMUNICACIÓN
        </Typography>
        <Box my={2} />
        <Typography variant="body1" textAlign={"center"}>
          Materiales y productos para intervenir en diferentes espacios con
          diversos actores y aportar a la prevención de la violencia hacia las
          mujeres en tu municipio.
        </Typography>
        <Box my={7} />
        <Box>
          <Grid container spacing={5}>
            {resources.map((item, index) => (
              <Grid item xs={12} md={6}>
                <ResourceCard
                  color={colors[index % 4]}
                  titulo={item.title}
                  descripcion={item.description}
                  recursos={item.resources}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </StyledBox>
  );
};
