import { Box, Typography, styled } from "@mui/material";
import theme from "../../../../themes";
import bgTop from "../../../../assets/WhoWeAre/bg_top_h.jpg";
import ButtonSocialMedia from "../../../../components/socialMedia/ButtonsSocialMedia";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  // height: 400,
  height: "70vh",
  backgroundImage: `url(${bgTop})`,
  backgroundAttachment: 'fixed',
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "fit-content",
    height: 500,
  },
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 1.5em 1.5em 1.5em",
  backgroundColor: "rgba(0, 124, 60, 0.6)",
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const MainBanner = () => {
  return (
    <StyledBox display="flex" justifyContent="center">
      <ButtonSocialMedia right={30} />
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledDivMediaCaption>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            sx={{ height: "100%", margin: 0, padding: 0 }}
          >
            <Box mb={2}>
              <Typography
                variant="h1"
                style={{
                  fontFamily: "Barlow",
                  fontSize: 42,
                  fontWeight: 400,
                  color: theme.palette.common.white,
                }}
              >
                ¿Quiénes somos?
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                Una vida digna significa crecer como personas, realizarnos ejerciendo plenamente nuestros derechos, tener oportunidades,
                 y contar con apoyo de la familia, el entorno y la comunidad, para cumplir metas y propósitos, y muy importante: vivir sin violencia.
              </Typography>
            </Box>
          </Box>
        </StyledDivMediaCaption>
      </Box>
    </StyledBox>
  );
};

export default MainBanner;
