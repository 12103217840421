import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Cloader from "../../shared/components/CLoader";
import Layout from "../../components/Layout";
import { Box, Button, Grid, Typography } from "@mui/material";
import ImgGenerica from "../../assets/img/fondo-validacion.png";
import Footer from "../../components/Footer";

export default function CertificateCheck() {
  const { certificateId } = useParams<{ certificateId: string }>();

  const [certificateData, setCertificateData] = useState<CertificateData>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getData = async () => {
    try {
      const response = await axios.get(
        "/api/check-certificate/" + certificateId
      );
      setCertificateData(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return <Cloader message={"Verificando estado del certificado"} />;
  if (error) return <p>Hubo un error en la verificación del certificado</p>;

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: "90vh",
          padding: "20px",
          boxSizing: "border-box",
          backgroundColor: "#ffffff",
          backgroundImage: `url(${ImgGenerica})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              padding: "20px",
              paddingLeft: { xs: "0", md: "100px" },
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                fontSize: "2.5rem",
                marginBottom: "10px",
              }}
            >
              SOLIDAR
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "400",
                fontSize: "1.5rem",
                marginBottom: "20px",
              }}
            >
              POR UNA VIDA SIN VIOLENCIA
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#007bbd",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Verificación del certificado
            </Typography>
            <Box sx={{ marginBottom: "15px" }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Curso
              </Typography>
              <Typography variant="body1">
                {certificateData?.courseName}
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Participante
              </Typography>
              <Typography variant="body1">
                {certificateData?.participantName}
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#f5a623",
                ":hover": {
                  backgroundColor: "#e59500",
                },
                padding: "10px 20px",
                fontSize: "1rem",
                fontWeight: "bold",
                maxHeight: "50px",
                maxWidth: "300px",
                marginTop: "20px",
              }}
              href={certificateData?.certificateUrl}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#ffffff",
                }}
              >
                Descargar certificado
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={12} md={4} />
        </Grid>
      </Box>
      <Footer />
    </Layout>
  );
}

interface CertificateData {
  participantName: string;
  courseName: string;
  certificateUrl: string;
}
