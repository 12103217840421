import {
  DeleteRounded,
  EditRounded,
  KeyboardBackspaceRounded as KeyboardBackspaceRoundedIcon,
  VerifiedRounded,
} from "@mui/icons-material";
import {
  Alert,
  alpha,
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DesignSystem } from "../../../themes/theme";
import { useDeleteBanner, useGetBanners } from "./data";
import { printLog } from "../../../shared/utils";
import theme from "../../../themes";
import { FormBannerComponent } from "./components/FormBannerComponent";
import { BannerDetalle } from "./BannerInterface";
import { Alerts } from "../../../shared/hooks/useAlert";

export const CommunicationBannerPage = () => {
  // const { user } = useAuthContext();
  const navigate = useHistory();
  const [bannerDialog, setBannerDialog] = useState<boolean>(false);
  const [bannerId, setBannerId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [banners, setBanners] = useState<BannerDetalle[]>([]);

  const getBanners = useGetBanners();
  const deleteBanner = useDeleteBanner();

  async function fetchBanners() {
    setLoading(true);
    const fetchedBanners = await getBanners();
    printLog("banners fetcheadas :: ", fetchedBanners);
    setBanners(fetchedBanners as unknown as []);
    setLoading(false);
  }

  useEffect(() => {
    fetchBanners();
    return () => {
      setBanners([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenBanner = () => {
    setBannerDialog(true);
    setBannerId(null);
  };

  const handleCloseBanner = () => {
    setBannerDialog(false);
  };

  const handleOnProcesarBanner = async () => {
    await fetchBanners();
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={6}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate.push("/admin/comunicacion", {
                replace: true,
              })
            }
          >
            <KeyboardBackspaceRoundedIcon />
          </IconButton>
          <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
            Banner destacado
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              printLog("abrir modal para agregar banner destacado");
              handleOpenBanner();
            }}
          >
            Agregar +
          </Button>
        </Box>
      </Box>
      <Box my={3} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
        px={3}
      >
        {loading && <LinearProgress />}
        {!loading && !banners.length && (
          <Box sx={{ width: "100%" }}>
            <Alert severity="warning">No se tiene datos de banners</Alert>
          </Box>
        )}
        {!loading &&
          !!banners.length &&
          banners.map((banner, idxBanner) => (
            <Card
              key={idxBanner}
              elevation={0}
              sx={{
                p: 2,
                width: "100%",
                borderRadius: 2,
              }}
            >
              <Box display={"flex"} alignItems={"center"} gap={3}>
                <Box>
                  <IconButton
                    size="small"
                    color="error"
                    title="Eliminar banner"
                    disabled={loading || banner.esDestacado}
                    onClick={() => {
                      printLog("eliminar banner");
                      Alerts.showConfirm({
                        title: "¿Estás segura(o) de eliminar el banner?",
                        description:
                          "Tenga en cuenta que no podrá recuperar los datos del banner eliminado luego de confirmar.",
                        onConfirm: async () => {
                          const response = await deleteBanner(banner.id!);
                          if (response) {
                            await fetchBanners();
                          }
                        },
                      });
                    }}
                  >
                    <DeleteRounded />
                  </IconButton>

                  <IconButton
                    size="small"
                    color="warning"
                    title="Editar banner"
                    disabled={loading}
                    onClick={() => {
                      printLog("editar banner");
                      setBannerId(banner.id!);
                      setBannerDialog(true);
                    }}
                  >
                    <EditRounded />
                  </IconButton>
                  {banner.esDestacado && (
                    <Chip
                      variant="outlined"
                      color="success"
                      size="small"
                      sx={{
                        background: alpha(theme.palette.success.main, 0.1),
                      }}
                      label={
                        <Typography variant="body2" fontWeight={"bold"}>
                          Destacado
                        </Typography>
                      }
                      icon={<VerifiedRounded />}
                    />
                  )}
                </Box>
                <Typography
                  variant="body1"
                  fontWeight={"medium"}
                  sx={{ flex: 1 }}
                >
                  {banner.titulo}
                </Typography>
              </Box>
            </Card>
          ))}
      </Box>
      {bannerDialog && (
        <FormBannerComponent
          bannerId={bannerId}
          onProcesarBanner={handleOnProcesarBanner}
          bannerDialog={bannerDialog}
          onClose={handleCloseBanner}
          loading={false}
        />
      )}
    </React.Fragment>
  );
};
